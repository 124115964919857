import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";

const AboutMember = ({ data }) => {
	console.log("Read whatWeDo data", data);

	return (
		<Layout>
			<h1>{data.post.title}</h1>
			<div dangerouslySetInnerHTML={{ __html: data.post.content }} />
		</Layout>
	);
};

export default AboutMember;

export const pageQuery = graphql`
	query ($id: String!) {
		post: wpPost(id: { eq: $id }) {
			id
			title
			content
			categories {
				nodes {
					name
					uri
				}
			}
		}
	}
`;
